/**
 * vue on-enter js 监听enter时，如果在中文输入法下输入英文，使用回车选中内容时会触发enter事件
 * 使用jQuery keydown 解决此问题
 */

import Vue from 'vue'

const jqueryEnter = {
  // eslint-disable-next-line
  inserted(el, binding) {
    // eslint-disable-next-line
    $(el).keydown(function (e) {
      if (e.keyCode === 13) {
        Vue.nextTick(() => {
          if (binding.value) {
            binding.value(el);
          }
        })
      }
    })
  },
  unbind: function (el) {
    // eslint-disable-next-line
    $(el).unbind("keydown")
  }
}

export default jqueryEnter
